import OfferCarsV2 from "./OfferCarsV2";

const OffersWrapperV2 = ({ isNew, title, deals }) => {
  return (
    <OfferCarsV2
      isNew={isNew}
      title={title}
      offers={deals.posts}
    />
  );
};

export default OffersWrapperV2;
